import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Rba from "../assets/rba";

import VideoItem from "./commons/VideoItem";
import axios from "axios";
import { Link } from "react-router-dom";

function Medias({ isMobile }) {
    const [videos, setVideos] = useState([]);

    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${months[newDate.getMonth()]
            } de ${newDate.getFullYear()}`;
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        //document.getElementsByClassName("twitter-embed")[0].appendChild(script);

        const loadVideos = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/videos?limit=2`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setVideos(resp.data);
        };

        loadVideos();
    }, []);

    return (
        <Fragment>
            <Grid container marginY={6} display={"flex"} gap={2} paddingX={2}>
                <Grid
                    xs={12}
                    md={12}
                    marginX={isMobile ? 0 : 8}
                    marginTop={4}
                    color="#202020"
                    borderBottom={1}
                >
                    <Typography
                        color="#202020"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Mídias e redes
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={12}
                    display={`flex`}
                    justifyContent={`center`}
                >
                    <Grid
                        xs={12}
                        md={8}
                        marginLeft={isMobile ? 0 : 8}
                        marginTop={4}
                        color="#202020"
                    >
                        <Grid container marginY={2} display={"flex"}>
                            <Grid sm={12} md={8}>
                                <Typography
                                    color="#202020"
                                    width={"fit-content"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="18px"
                                    lineHeight="21px"
                                >
                                    TV Sindsep
                                </Typography>
                            </Grid>
                            {!isMobile && (
                                <Grid
                                    md={4}
                                    padding={"0px 0px 16px 0px"}
                                    color="#FFFFFF"
                                    borderBottom={1}
                                    display={"flex"}
                                >
                                    <Link
                                        aria-label="videos"
                                        to={`/videos`}
                                        rel="noreferrer"
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: 100,
                                                color: "#EB2227",
                                                padding: "8px 16px",
                                                fontFamily: "Raleway",
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "15px",
                                                lineHeight: "16px",
                                                borderColor: "#EB2227",
                                            }}
                                        >
                                            VEJA TODOS OS VÍDEOS
                                        </Button>
                                    </Link>
                                </Grid>
                            )}
                            <Grid xs={12} md={5} marginTop={2} marginRight={4} color="#202020">
                                {videos.length !== 0 && (
                                    <VideoItem
                                        image={`https://img.youtube.com/vi/${videos[0].youtube}/hqdefault.jpg`}
                                        date={formatDate(videos[0].incData)}
                                        description={videos[0].titulo}
                                        link={`https://youtube.com/watch?v=${videos[0].youtube}`}
                                    />
                                )}
                            </Grid>
                            <Grid xs={12} md={5} marginTop={2}  color="#202020">
                                {videos.length !== 0 && (
                                    <VideoItem
                                        image={`https://img.youtube.com/vi/${videos[1].youtube}/hqdefault.jpg`}
                                        date={formatDate(videos[1].incData)}
                                        description={videos[1].titulo}
                                        link={`https://youtube.com/watch?v=${videos[1].youtube}`}
                                    />
                                )}
                            </Grid>
                            {isMobile && (
                                <Grid
                                    xs={12}
                                    md={12}
                                    padding={"0px 0px 16px 0px"}
                                    color="#FFFFFF"
                                    borderBottom={1}
                                    display={"flex"}
                                >
                                    <Link
                                        aria-label="videos"
                                        to={`/videos`}
                                        rel="noreferrer"
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: 100,
                                                color: "#EB2227",
                                                padding: "8px 16px",
                                                fontFamily: "Raleway",
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                lineHeight: "20px",
                                                borderColor: "#EB2227",
                                            }}
                                        >
                                            VEJA TODOS OS VÍDEOS
                                        </Button>
                                    </Link>
                                </Grid>
                            )}
                            <Grid container marginY={2} display={"flex"}>
                                <Grid sm={12} md={8}>
                                    <Typography
                                        color="#202020"
                                        width={"fit-content"}
                                        fontFamily="Raleway"
                                        fontStyle="normal"
                                        fontWeight="700"
                                        fontSize="18px"
                                        lineHeight="21px"
                                    >
                                        Lives Sindsep
                                    </Typography>
                                </Grid>
                                {!isMobile && (
                                    <Grid
                                        md={4}
                                        padding={"0px 0px 16px 0px"}
                                        color="#FFFFFF"
                                        borderBottom={1}
                                        display={"flex"}
                                    >
                                        <a
                                            aria-label="redirecionamento para pagina do facebook lives"
                                            href={`https://www.facebook.com/sindsep/live_videos/?ref=page_internal`}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    backgroundColor: "#FFFFFF",
                                                    borderRadius: 100,
                                                    color: "#EB2227",
                                                    padding: "8px 16px",
                                                    fontFamily: "Raleway",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "15px",
                                                    lineHeight: "16px",
                                                    borderColor: "#EB2227",
                                                }}
                                            >
                                                VEJA TODOS AS LIVES
                                            </Button>
                                        </a>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid xs={12} md={5} marginTop={2} marginRight={4} color="#202020">
                                <VideoItem
                                    image={"./files/fb1.jpg"}
                                    date={"01 de Abril de 2023"}
                                    description={
                                        'Aula inaugural do curso "A construção da identidade dos educadores dos CEIs no município de São Paulo".'
                                    }
                                    link="https://www.facebook.com/watch/live/?ref=watch_permalink&v=1614855838927325"
                                />
                            </Grid>
                            <Grid xs={12} md={5} marginTop={2} color="#202020">
                                <VideoItem
                                    image={"./files/fb2.jpg"}
                                    date={"30 de Março de 2023"}
                                    description={
                                        "Ato de lançamento da campanha “O serviço é público, meu corpo não”"
                                    }
                                    link="https://www.facebook.com/sindsep/videos/713384130483746"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                marginTop={6}
                gap={4}
                display={"flex"}
                justifyContent={"center"}
                backgroundColor="#EAECF0"
                padding={4}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginX={8}
                    marginTop={4}
                    color="#202020"
                    borderBottom={1}
                >
                    <Typography
                        color="#202020"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Parceiros de Luta
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={4}
                    backgroundColor={"#202020"}
                    borderRadius={"12px"}
                >
                    <a
                        href="https://www.redebrasilatual.com.br"
                        target={"_blank"}
                        aria-label="redirecionamento para rede brasil atual"
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"0px 14px 14px 14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px 34px"}
                                display={"flex"}
                                alignItems={"center"}
                                width={"94%"}
                                gap={2}
                            >
                                <Rba />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="26px"
                                    lineHeight="29px"
                                    color="#202020"
                                >
                                    Jornalismo pela transformação da sociedade
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 100,
                                    color: "#202020",
                                    marginTop: 16,
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                CONFIRA AGORA AS NOTÍCIAS
                            </Button>
                        </Box>
                    </a>
                </Grid>
                <Grid
                    xs={12}
                    md={4}
                    backgroundColor={"#202020"}
                    borderRadius={"12px"}
                >
                    <a
                        aria-label="redirecionamento para tvt"
                        href="https://www.tvt.org.br"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"0px 14px 14px 14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px 34px"}
                                display={"flex"}
                                alignItems={"center"}
                                width={"94%"}
                                gap={2}
                            >
                                <img src="../files/tvtLarge.png" alt="logo da tvt" />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="26px"
                                    lineHeight="29px"
                                    color="#202020"
                                >
                                    Comunicação educativa e compromisso com a
                                    democracia
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 100,
                                    color: "#202020",
                                    marginTop: 16,
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                CONFIRA AGORA AS NOTÍCIAS
                            </Button>
                        </Box>
                    </a>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Medias;

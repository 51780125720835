import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";

function VideoItem({
    date,
    image,
    description,
    link,
    categorie,
    categorieColor,
}) {
    return (
        <Box marginBottom={2}>
            <a
                href={link}
                aria-label={`redirecionamento para o video ${description}`}
                rel="noreferrer"
                style={{
                    textDecoration: "none",
                }}
            >
                <CardMedia
                    component="img"
                    height="280px"
                    image={image}
                    style={{ objectFit: "cover" }}
                    alt="collection image"
                />
                <Grid container display={"flex"} gap={2} mt={1}>
                    <Grid xs={6} md={"auto"}>
                        <Typography
                            color="#010101"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="14px"
                        >
                            {date}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={"auto"}
                        md={"auto"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "2px 12px",
                            backgroundColor: categorieColor,
                            borderRadius: 50,
                        }}
                    >
                        <Typography
                            color="#010101"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="10px"
                            lineHeight="14px"
                            letterSpacing={"0.1em"}
                        >
                            {categorie}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography
                    color="#010101"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="20px"
                >
                    {description}
                </Typography>
            </a>
        </Box>
    );
}

export default VideoItem;

import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import DiretoriaComponent from "../components/Diretoria";
import SiteMap from "../components/SiteMap";
import { Box, Typography } from "@mui/material";

const Politica = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <Box
                marginY={6}
                gap={2}
                display={"flex"}
                flexDirection={`column`}
                paddingX={8}
                marginBottom={32}>
                <Typography
                    color="#010101"
                    width={"fit-content"}
                    borderBottom={1}
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="800"
                    fontSize="20px"
                    lineHeight="23px"
                >
                    Política de Privacidade

                </Typography>
                <Typography
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="35px"
                    color="#202020"
                    marginTop={1}
                    whiteSpace={"pre-line"}
                >

                    {`POLÍTICA DE PRIVACIDADE DO SINDSEP
conforme a Lei Geral de Proteção de Dados (LGPD) 
 
 
O SINDICATO DOS TRABALHADORES NA  ADMINISTRAÇÃO PÚBLICA E AUTARQUIAS DO MUNICÍPIO DE SÃO PAULO - SINDSEP tem por objetivo ser instrumento de luta da categoria, prezando pela dignidade e proteção dos trabalhadores do serviço público municipal, inclusive de seus dados.
 
O sigilo de suas informações e a sua privacidade são muito importantes para nós. Quando você nos procura, utiliza nossos serviços ou se sindicaliza, tomamos os cuidados necessários para garantir o sigilo, a proteção e o uso adequado dos seus dados pessoais, nos termos da Lei Geral de Proteção de Dados (Lei 13.709 de 14 de agosto de 2018). 
 
Essa Política de Privacidade visa a esclarecer e conferir transparência a todo o tratamento de dados pessoais realizado pelo SINDSEP e pode ser alterada e atualizada sem aviso prévio, de forma que recomendamos que a consulte regularmente.
 
1. CONCEITUAÇÃO
 
a. Dados pessoais: qualquer informação fornecida e/ou coletada pelo SINDSEP que se relaciona a uma pessoa natural, identificada ou  identificável (ex: nome, endereço, telefone, e-mail, RG, CPF, dados de localização). Os dados pessoais não incluem telefone comercial,  número de celular comercial, endereço comercial, e-mail comercial.
 
b. Dados pessoais sensíveis: informação fornecida e/ou coletada pelo SINDSEP que trata sobre:
             i. Origem racial ou étnica
             ii. Convicção religiosa
             iii. Opinião política
             iv. Filiação ao sindicato ou à organização de caráter religioso, filosófico ou político
             v. Saúde ou vida sexual
            vi. Dados genético ou biométrico, vinculado a uma pessoa natural
 
c. Titular: pessoa natural a quem se referem os dados pessoais.
 
d. Controlador: pessoa física ou jurídica a quem competem as decisões referentes ao tratamento de dados pessoais.
 
e. Operador: pessoa física ou jurídica que realiza o tratamento de dados pessoais em nome do controlador.
 
f. Encarregado: pessoa indicada pelo controlador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a  ANPD.
 
g. ANPD: Autoridade Nacional de Proteção de Dados.
 
h. Direitos dos Titulares: toda pessoa natural tem assegurada a titularidade de seus dados pessoais e garantidos os direitos fundamentais de  liberdade, intimidade e privacidade. Por isso, o SINDSEP garante que você possa:
 
i. Confirmar a existência de seus dados em nosso banco de dados
ii. Ter acesso aos seus dados
iii. Corrigir os dados incompletos, inexatos ou desatualizados
iv. Fazer a portabilidade de seus dados para outro Sindicato
v. Eliminar os dados que são coletados e armazenados por meio do seu consentimento
vi. Conhecer as instituições, públicas ou privadas, com quem compartilhamos seus dados
vii. Consentir com a utilização dos dados e retirar seu consentimento quando quiser, desde que ciente das consequências
 
    
2. COMO COLETAMOS SEUS DADOS PESSOAIS
 
Na ficha de sindicalização coletamos os dados necessários para o funcionamento de nossos serviços e para a defesa de seus direitos individuais e coletivos.
 
Seus dados são coletados quando você comparece a nossa sede, envia e-mails, mensagens de WhatsApp, SMS, telefona para nós, se inscreve na nossa Newsletter, fala conosco através do site, participa de nossas plenárias, assembleias, reuniões, cursos e eventos, utiliza de nossos serviços jurídicos ou fornece suas informações aos nossos diretores.
 
Podemos receber dados relacionados à sua situação funcional por meio da Prefeitura, em especial: sua data de admissão, exoneração, aposentadoria, salário e informações de pagamento das contribuições mensais.
 
Seus dados podem ser coletados pelo nosso site.
 
Assim, podem ser alvo de coleta, conforme finalidades especificas e base legal aplicável:
 
a. Dados pessoais de identificação: nome, CPF, Registro Funcional (RF), estado civil, secretaria, unidade, departamento, setor, cargo/função,  data de admissão, regime de trabalho, cópia de documento com foto, entre outros.
b. Dados pessoais de contato: e-mail, celular, telefone, endereço, entre outros.
c. Dados pessoais sensíveis: gênero, data de nascimento, cor, escolaridade, deficiência, salário, entre outros.
 
 
3. COMO SEUS DADOS SÃO UTILIZADOS
 
O SINDSEP usa os dados pessoais para defender os interesses da categoria e representá-la, atuar em processos judiciais, administrativos ou arbitrais, atuar em formação profissional, promover estudos e relatórios, divulgar informativos, campanhas e benefícios, comunicar-se com você e cumprir a lei. Também podemos usar os dados pessoais para outros fins, com ou sem o seu consentimento.
 
O SINDSEP usa seus dados pessoais legitimado pela adequada base legal. Em algumas circunstâncias, o SINDSEP precisa do seu consentimento; em outras, realiza o tratamento para cumprir um contrato/compromisso com você, para proteger seus interesses vitais ou de outras pessoas ou, ainda, para cumprir a lei. Também podemos tratar seus dados pessoais, quando tivermos legítimo interesse ou houver interesses legítimos de terceiros, levando em consideração seus direitos e expectativas. Se você tiver dúvidas sobre a base legal do tratamento, entre em contato com nosso Canal de Atendimento aos Direitos dos Titulares pelo e-mail lgpd@sindsep-sp.org.br .
 
O SINDSEP preza pelo tratamento mínimo de dados, mantendo apenas o que compreende os requisitos de finalidade, adequação e necessidade, se comprometendo a manter as melhores medidas de segurança nas operações que envolvam os seus dados, cobrando a mesma postura dos terceiros com quem, por força de relação contratual, precisa realizar algum compartilhamento dos dados.
 
a. Defesa dos interesses da categoria e representação
Os dados pessoais são utilizados para a atuação do Sindicato na defesa dos interesses da categoria atendendo aos princípios e objetivos do Sindicato.
O SINDSEP realiza assembleias e votações, contribui com a formação profissional, recebe reclamações e denúncias, entre várias outras atividades.
 
b. Atendimento jurídico e voltado para formação profissional
Os dados pessoais podem ser utilizados no atendimento jurídico, coletivo ou individual, situação em que haverá o compartilhamento de dados com a nossa assessoria jurídica.
 
Em caso de procedência de demandas coletivas movidas pelo Sindicato, os valores pecuniários obtidos são transferidos aos beneficiários. O SINDSEP poderá, portanto, tratar dados bancários imprescindíveis para os pagamentos/repasses devidos.
 
O Sindicato, através de sua Escola de Formação, realiza cursos, seminários, rodas de conversas, palestras etc. Para tanto, pode compartilhar dados dos inscritos com as equipes responsáveis por cada evento.
 
         c. Promoção de estudos e relatórios
Por meio da anonimização dos dados, o SINDSEP pode realizar estudos para estabelecer estratégias de atuação enquanto entidade representativa de classe. Tais relatórios podem conter média salarial, quantidade de sindicalizações e desfiliações, inserção e percentual de mulheres, pessoas negras, pessoas com deficiência e pessoas do grupo LGBTQIA+ na Prefeitura, estudo de condições de trabalho, entre outras informações relevantes.
 
         d. Divulgação de informativos, campanhas e benefícios
Podemos utilizar os seus dados de contato para fins de veiculação de informativos e campanhas voltadas para o interesse da categoria profissional e de seus dependentes, além de promover informações e campanhas culturais para a categoria e o público em geral, bem como convênios visando a oferta de benefícios de desconto e promoções aos sindicalizados.
 
Caso não deseje o envio de informativos e demais notícias promovidas pelo SINDSEP, o titular dos dados poderá requer sua exclusão das listas, encaminhando um e-mail para lgpd@sindsep-sp.org.br . 
 
        e. Comunicação com o titular de dados
Utilizamos os seus dados para entrar em contato diretamente com você, seja em relação à solicitação de atendimento e informação, pesquisas de satisfação, ou até mesmo para fins associativos, registrando todos os atendimentos realizados.
 
         f. Utilização de dados sensíveis
Em relação aos seus dados sensíveis, temos, em primeiro lugar, sua condição de sindicalizado. É por meio dela que o SINDSEP exerce a defesa dos interesses da categoria e representação, cumprindo com nossa obrigação constitucional.
 
A sua condição de sindicalizado também é utilizada para viabilizar o acesso aos benefícios oferecidos pelo Sindicato: política de descontos, por meio de convênios com empresas parceiras, Colônias de Férias, cursos, eventos e festas.
 
Os convênios podem ser visualizados no nosso site: https://sindsep-sp.org.br/servicos. O Sindicato não compartilha dados com as empresas conveniadas, bastando que você comprove sua condição de sindicalizado. Quando for necessário o compartilhamento de informações, pediremos sua autorização prévia.
 
Em demandas judiciais, pode ser necessário o tratamento de dados pessoais sensíveis relacionados a acidentes de trabalho e/ou doenças profissionais. 
 
Seus dados bancários poderão ser utilizados para repasse de verbas obtidas judicialmente pelo Sindicato, assim como cobrança e reembolso de mensalidades.
 
Finalmente, podemos tratar os dados de seus dependentes para garantir os mesmos benefícios oferecidos ao sindicalizado. 
 
    
4. DADOS COMPARTILHADOS
 
O SINDSEP pode compartilhar dados pessoais com prestadores de serviços vinculados à nossa atividade, nossos parceiros ou terceiros sob sua orientação.
 
• Prestadores de serviço: O Sindicato poderá contratar terceiros para atuar como prestadores de serviços e realizar determinadas tarefas em  nosso nome, como processar ou armazenar dados (incluindo dados pessoais). Os prestadores de serviços do SINDSEP devem processar  os dados pessoais de acordo com esta Política de Privacidade e de acordo com as nossas instruções. Eles não podem usar os dados  pessoais que compartilhamos para seus próprios propósitos e devem apagar ou devolver os dados pessoais assim que atenderem à nossa  solicitação.
• Parceiros: Às vezes, o SINDSEP pode estabelecer parceria com terceiros para viabilizar nossos objetivos e princípios. O Sindicato exige  que os parceiros protejam seus dados pessoais.
• Terceiros: O SINDSEP pode compartilhar dados pessoais com terceiros sob sua orientação ou com seu consentimento. Poderemos  também compartilhar informações sobre você se determinarmos que, para finalidades de segurança nacional, aplicação da lei ou outras  questões de importância pública, o compartilhamento é necessário e pertinente. Poderemos também compartilhar informações sobre você,  desde que haja uma base legal para isso, se determinarmos que o compartilhamento é razoavelmente necessário para cumprir os nossos  termos e condições ou proteger nossas operações ou sindicalizados.
 
a. Curso de Formação
Quando você nos procura com interesse em participar de cursos de formação profissional, seus dados, acompanhados ou não de ficha de  inscrição, são compartilhados com a equipe responsável.
 
b. Jurídico
Para ações judiciais coletivas e/ou individuais, dúvidas e atendimentos jurídicos, o Sindicato compartilha seus dados com nossa assessoria  jurídica. 
 
c. Com outro sindicato
Conforme prevê a lei, você tem o direito, mediante requisição expressa, de requerer a portabilidade de seus dados para outro sindicato.  Para tanto, você deverá entrar em contato conosco e nós compartilharemos seus dados com o sindicato de sua nova base.
 
d. Convênios
Nossos convênios não exigem do Sindicato o compartilhamento de dados pessoais dos sindicalizados e/ou seus dependentes. Para fazer  uso dos descontos e vantagens, basta que você demonstre sua condição de sindicalizado.
Quando houver a necessidade de compartilhamento de dados, pediremos previamente seu consentimento em termo específico. 
 
e. Eventos
O Sindicato realiza diversos eventos, físicos e/ou digitais, por exemplo: lives, promoções, festas. Para viabilizar os eventos, precisamos  compartilhar os dados dos participantes com empresas parceiras. O compartilhamento será sempre informado, caso a caso, aos  participantes, colhendo-se, se o caso, as autorizações devidas. 
 
f. Colônia de Férias
O Sindicato possui parceria com diversas colônias de férias. A lista pode ser visualizada em nosso site https://sindsep-sp.org.br/colonias. Para que sua viagem seja viabilizada, seus dados de reserva e pagamento são compartilhados com as colônias. 
 
g. Contribuições Diversas
Dados dos associados poderão ser encaminhados à empregadora (Prefeitura) para a implementação de descontos legais ou negociais.
 
h. Desenvolvedores e Suporte
O Sindicato preza pela segurança de seus dados e, por isso, trabalha com parceiros de desenvolvimento e suporte de softwares. Utilizamos  servidor próprio para armazenamento de seus dados pessoais, bem como possuímos sistemas específicos que garantem o tratamento de  dados de forma adequada e segura. 
O desenvolvimento, manutenção e atualização do nosso site, servidor, sistema de assembleias e votação online e sistemas internos podem  ocasionar o compartilhamento de alguns de seus dados com desenvolvedores e suporte, para isso, o Sindicato exige de seus parceiros as  melhores práticas de segurança. 
 
i. Motivos legais para divulgação de dados
Em certas circunstâncias, o SINDSEP poderá/deverá divulgar seus dados pessoais, na medida necessária e apropriada, para órgãos governamentais, consultores ou terceiros com o objetivo de cumprir com a LGPD ou com uma ordem ou intimação judicial. Sempre que possível seus dados serão anonimizados antes de tal fornecimento.
 
    
5. GERENCIAMENTO E MANUTENÇÃO DOS DADOS  
 
O Canal de Atendimento aos Direitos dos Titulares, que funciona por meio do e-mail lgpd@sindsep-sp.org.br , visa demonstrar o compromisso do SINDSEP com o acesso a dados pessoais, seja de sindicalizados, funcionários, visitantes, parceiros conveniados, entre outros. 
 
O titular pode, a qualquer momento, solicitar ao SINDSEP informações sobre o tratamento dos seus respectivos dados pessoais e exercer os seus direitos enquanto titular. 
 
Os dados coletados pelo SINDSEP guardam a finalidade descrita nesta Política de Privacidade e, tendo em vista a natureza da entidade e a representação de classe, podem ser tratados independente do consentimento do titular, desde que se relacionem com outra base legal prevista na Lei Geral de Proteção de Dados.
 
 
6. SEGURANÇA DA INFORMAÇÃO
 
O SINDSEP reforça o intuito de dar transparência ao tratamento dos dados pessoais em conformidade com a Lei Geral de Proteção de Dados, se comprometendo a implementar os melhores mecanismos de segurança, com protocolos internos de gerenciamento dos dados, além de cobrar o mesmo compromisso por parte dos seus parceiros comerciais e demais colaboradores, com atendimento à estrita finalidade descrita nesta Política de Proteção de Dados.
 
 
7. RESPOSTAS A PERGUNTAS COMUNS
 
a. Como faço para saber quais dados são tratados?
O SINDSEP possui um Canal de Atendimento aos Direitos dos Titulares pelo e-mail: lgpd@sindsep-sp.org.br . Toda dúvida, solicitação, sugestão ou crítica sobre o tratamento de seus dados deve ser encaminhada via e-mail. A equipe do SINDSEP irá analisar a sua solicitação e responder o mais rápido possível.
 
b. Como faço para atualizar meus dados?
Caso tenha algum dado que esteja desatualizado, pedimos a gentileza de entrar em contato com o nosso Canal de Atendimento aos Direitos dos Titulares, pelo e-mail: lgpd@sindsep-sp.org.br , para que nosso setor responsável realize a alteração, informando quando o processo for concluído.
 
c. Papel do consentimento
O consentimento permite que o titular tenha acesso aos dados e às informações pertinentes ao tratamento de seus dados pessoais, de forma que a resposta deve ser clara e específica para, em seguida, ser disponibilizada de maneira adequada para o titular.
 
d. Revogação do consentimento
A qualquer momento o titular dos dados poderá entrar em contato com o Canal de Atendimento aos Direitos dos Titulares do SINDSEP, pelo e-mail: lgpd@sindsep-sp.org.br , e solicitar o cancelamento do consentimento.
 
e. Anonimização, bloqueio ou eliminação
O processo de anonimização é a técnica utilizada para remover ou modificar informações que identifiquem uma pessoa. Desta maneira, o dado será considerado anonimizado quando não for possível identificar o indivíduo de origem.
 
Além disso, quando o titular do dado entender que seu dado está sendo utilizado de maneira desnecessária, excessiva ou tratado em desconformidade com a LGPD, poderá requerer a anonimização, bloqueio ou eliminação desses dados, desde que os dados não sejam utilizados para o cumprimento de alguma outra base legal diversa do consentimento, podendo em alguns casos implicar na perda de um serviço ou benefício ofertado.
 
Importante frisar que a anonimização é irreversível ou de difícil reversão, já que assegura a proteção dos dados pessoais, como nos casos de pesquisas.
 
f. Como exercer os direitos dos titulares?
Os direitos dos titulares, enumerados nesta Política de Privacidade de acordo com a previsão contida na Lei Geral de Proteção de Dados, podem ser exercidos pelo titular mediante envio de solicitação para o e-mail lgpd@sindsep-sp.org.br .
 
Nos termos da Lei Geral de Proteção de Dados, alguns dados relativos ao usuário, a depender da base legal, não poderão ser eliminados pelo controlador, mesmo que diante da solicitação. 
 
Caso o usuário requeira a eliminação de um dado que não depende do prévio consentimento, o SINDSEP se compromete a realizar resposta fundamentada dos motivos da não exclusão. 
 
 
8. ANÚNCIOS
 
Tal como outros sites, podemos coletar e utilizar informação contida nos anúncios. A informação contida nos anúncios inclui seu endereço IP (Internet Protocol), o navegador que utilizou ao visitar o nosso site (como o Internet Explorer ou o Firefox), o tempo da sua visita e que páginas visitou dentro do nosso site.
 
 
9. CANAL DE ATENDIMENTO SOBRE TRATAMENTO DE DADOS
 
Se houver alguma outra dúvida, pedimos a gentileza de entrar em contato com o nosso Canal de Atendimento aos Direitos dos Titulares, pelo e-mail lgpd@sindsep-sp.org.br . 
 
    
10. POLÍTICA DE COOKIES
 
Os sites, serviços online e aplicativos interativos do Sindicato podem usar “cookies” e outras tecnologias, como web beacons. Essas tecnologias nos ajudam a entender melhor o comportamento dos usuários, inclusive para fins de segurança e prevenção de fraudes, e nos informam quais seções dos nossos sites as pessoas visitaram, além de avaliar e contribuir para a eficácia dos anúncios e buscas na Internet.
 
Cookies de comunicação: Esses cookies são usados para permitir o tráfego de rede dos sistemas do SINDSEP e para esses sistemas,  inclusive ajudando-nos a detectar erros.
Cookies estritamente necessários: Esses cookies são definidos conforme necessário para fornecer um recurso ou serviço específico que  você acessou ou solicitou.
Outros cookies: Esses cookies são usados para entender como os visitantes interagem com o nosso site e serviços online e nos  ajudam a avaliar a eficácia das páginas. O Sindicato também pode usar esses cookies para lembrar as escolhas que você faz durante a  navegação para garantir uma experiência personalizada.
 
Alguns dos cookies utilizados pelo Sindicato são:
 
i. Google Analytics: permite analisar o comportamento/engajamento do usuário em um site/app, com a coleta de endereço IP, atividades no  site/app, informações estatísticas e ativar funcionalidades como o remarketing. Leia mais em:  https://support.google.com/analytics/answer/6004245?hl=pt-BR;
 
Os cookies podem ser armazenados por prazo indeterminado ou até que o cache do navegador seja limpo ou alterado pelo usuário.
 
Se você preferir que o SINDSEP não use cookies, existem meios para desativar seu uso. Para desativar os cookies no navegador Safari, selecione a opção “Bloquear todos os cookies” nos ajustes de privacidade do Safari. Se estiver usando outro navegador, verifique com seu provedor para saber como desativar os cookies. Alguns recursos do site do Sindicato poderão ficar indisponíveis se todos os cookies estiverem desativados.`}
                </Typography>
            </Box>
            <Footer />
            <SiteMap />
        </div>
    );
};

export default Politica;

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

function SiteMap() {
    const [width, setWidth] = useState(window.innerWidth);
    const [menu, setMenu] = useState([]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        const loadServices = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/services?limit=300`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            const aux = [];

            resp.data.map((obj, index) => {
                if (
                    resp.data
                        .map((item) => item.categoria)
                        .indexOf(obj.categoria) === index
                )
                    aux.push(obj.categoria);
                return true;
            });

            aux.sort();

            aux.unshift("TODOS OS BENEFÍCIOS");

            setMenu(aux);
        };

        loadServices();

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor="#EAECF0" paddingTop={2}>
            <Typography
                color="#EB2227"
                marginLeft={isMobile ? 2 : 8}
                marginTop={2}
                fontFamily="Raleway"
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
            >
                Mapa do Site
            </Typography>
            <Grid
                container
                marginLeft={isMobile ? 2 : 8}
                marginTop={6}
                gap={8}
                maxWidth={"80vw"}
            >
                <Grid xs={12} md={"auto"}>
                    <Link
                        to="/"
                        aria-label="home"
                        style={{
                            color: "#202020",
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            color="#202020"
                            borderBottom={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="20px"
                        >
                            O Sindsep
                        </Typography>
                    </Link>
                    <Grid gap={2}>
                        <Link
                            to="/diretoria"
                            aria-label="home"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Diretoria
                            </Typography>
                        </Link>
                        <a
                            href="https://sindicalizacao.sindsep-sp.org.br/"
                            aria-label="redirecionamento para sindicalização online"
                            rel="noreferrer"
                            target={"_blank"}
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Sindicalize-se
                            </Typography>
                        </a>
                        {/* att
                        <Link
                            to="/atualize-seu-cadastro"
                            aria-label="link para atualização cadastral"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Atualize Seu Cadastro
                            </Typography>
                        </Link>*/}
                        {/* 
                        <Typography
                            marginY={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="20px"
                            color="#202020"
                        >
                            Politica De Privacidade
                        </Typography>
                        */}
                    </Grid>
                </Grid>
                <Grid xs={12} md={"auto"}>
                    <Link
                        to="/servicos"
                        aria-label="home"
                        style={{
                            color: "#202020",
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            color="#202020"
                            borderBottom={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="20px"
                        >
                            Serviços
                        </Typography>
                    </Link>
                    <Grid gap={2}>
                        <Link
                            to="/juridico"
                            aria-label="home"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Consulta Jurídica
                            </Typography>
                        </Link>
                        <a
                            href="https://www.areaprivada.prefeitura.sp.gov.br/PortalPMSP/PortalPMSP/Portal/PMSPprt001.tp"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para prefeitura, no holerite eletronico"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Holerite Eletrônico
                            </Typography>
                        </a>
                        <a
                            href="https://diariooficial.prefeitura.sp.gov.br/md_epubli_controlador.php?acao=inicio"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para o diário oficial"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Diário Oficial da Cidade
                            </Typography>
                        </a>
                        <a
                            href="http://webmail.sindsep-sp.org.br"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-label="redirecionamento para o webmail do sindsep"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Webmail
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
                <Grid xs={12} md={4}>
                    <Typography
                        color="#202020"
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                    >
                        Benefícios
                    </Typography>
                    <Grid container gap={1} marginTop={1}>
                        {menu.map((item) => (
                            <Grid md={5.8}>
                                <Link
                                    to={`/servicos?benefits=${item}`}
                                    aria-label="home"
                                    style={{
                                        color: "#202020",
                                        textDecoration: "none",
                                    }}
                                >
                                    <Typography
                                        fontFamily="Raleway"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        fontSize="14px"
                                        lineHeight="20px"
                                        color="#202020"
                                    >
                                        {item}
                                    </Typography>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid xs={12} md={"auto"}>
                    <Typography
                        color="#202020"
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                    >
                        Comunicação
                    </Typography>
                    <Grid gap={2}>
                        <Link
                            to="/noticias"
                            aria-label="home"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Notícias
                            </Typography>
                        </Link>
                        <a
                            href="https://www.youtube.com/c/SindsepSãoPaulo"
                            rel="noreferrer"
                            target={"_blank"}
                            aria-label="redirecionamento para o youtube do sindsep"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                TV Sindsep
                            </Typography>
                        </a>
                        <Link
                            to="/galeria"
                            aria-label="home"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginY={1}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#202020"
                            >
                                Galeria de Fotos
                            </Typography>
                        </Link>
                    </Grid>
                    <a
                        href="https://api.whatsapp.com/send?phone=5511970255497"
                        rel="noreferrer"
                        aria-label="redirecionamento para o whatsapp do sindsep"
                        target={"_blank"}
                        style={{
                            color: "#202020",
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            color="#202020"
                            borderBottom={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="20px"
                            marginTop={6}
                        >
                            Fale conosco
                        </Typography>
                    </a>
                </Grid>
            </Grid>
            <Box backgroundColor={"#EB2227"} padding={"12px 0px"} marginTop={4}>
                <Grid container>
                    <Grid xs={12} md={9}>
                        <Typography
                            marginLeft={"20px"}
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                        >
                            2025 © Sindsep. Todos os direitos reservados.
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <Typography
                            marginLeft={"20px"}
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                        >
                            site desenvolvido por VIVEAGORA
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default SiteMap;

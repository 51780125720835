import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

function Diretoria() {
    return (
        <Fragment>
            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={8}
                marginBottom={32}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        DIRETORIA
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} marginTop={1}>
                    <Typography
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#010101"
                        whiteSpace={"pre-line"}
                    >
                        {`Gestão Resistir, Lutar e Avançar (2022-2026)`}
                    </Typography>
                </Grid>

                <Typography
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="35px"
                    color="#202020"
                    marginTop={1}
                    whiteSpace={"pre-line"}
                >
                    {`João Gabriel G. Buonavita - Presidente
                    Luciana Melo (Luba) - Vice-presidenta
                    Solange Cristina Ribeiro - Finanças
                    Sergio Ricardo Antiqueira - Geral
                    Lucianne Tahan - Formação
                    João Batista Gomes - Jurídico
                    Vlamir Lima - Imprensa
                    Charles de Jesus - Política Intersindical
                    Lourdes Estevão - Políticas Sociais
                    Ejivaldo Espírito Santo - Saúde do Trabalhador
                    Laudiceia Reis - Atenção a Mulher Trabalhadora
                    Roberto Alves - Políticas Culturais e Eventos
                    Flávia Anunciação - Trabalhadoras(es) da Saúde
                    Maciel Nascimento - Trabalhadoras(es) da Educação

                    COORDENAÇÕES DE REGIÃO

                    Manoel da Cruz Oliveira - Leste I
                    Luana Bife - Leste II
                    Aline Barbosa - Leste III
                    Maria Mota - Sudeste
                    Douglas Cardozo - Sul I 
                    Alonir Roberto (Viola) - Sul II
                    Lira Alli - Oeste
                    Fabiano de Oliveira - Noroeste
                    Sheila Costa - Norte
                    Luzia Barbosa - Centro
                    
                    DEPTOS. DE TRABALHADORAS(ES)
                    
                    José Teixeira - SAS, SEME, SMC, SMMA, SFMSP e IPREM
                    Djalma Prado - Aposentadas(os)
                    Eudes Wesley Dias Melo - Segurança Urbana

                    CONSELHO FISCAL
                    
                    José Francisco Lima
                    Sueli A. Guarniei
                    Onedil Luiza Bueno
                    Eni Pereira
                    Paulo Gomes

                    SUPLENTES DE DIREÇÃO

                    Juneia Martins
                    Elisangela Rugiere
                    Ligia Mendes
                    Sheila de Souza
                    Luiz Augusto Pardini
                    Manoel da Cruz
                    Giseli Siqueli
                    Patrícia Ferreira
                    Noemi Gomes
                    Priscila Tancredi
                    
                    SUPLENTE DE CONSELHO FISCAL
                    
                    Mônica Lins
                    Edgar Hideo`}
                </Typography>
            </Grid>
        </Fragment>
    );
}

export default Diretoria;
